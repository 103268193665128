import  { useState } from "react";

function Calculator() {
    const [display, setDisplay] = useState("");
    const [result, setResult] = useState(null);

    const handleClick = (value) => {
        if (value === "C") {
            setDisplay("");
            setResult(null);
        } else if (value === "=") {
            try {
                const evaluatedResult = evaluateExpression(display);
                setResult(evaluatedResult);
            } catch {
                setResult("Error");
            }
        } else {
            setDisplay(display + value);
        }
    };

    return (
        <div className="calculator w-64 h-96 grid grid-cols-4 gap-2 p-4">
            <div className="col-span-4 bg-gray-300 text-right p-2 text-black">
                {result !== null ? result : display}
            </div>
            {["1", "2", "3", "+", "4", "5", "6", "-", "7", "8", "9", "*", "C", "0", "=", "/"].map((btn) => (
                <button
                    key={btn}
                    onClick={() => handleClick(btn)}
                    className="bg-blue-500 text-black p-2 rounded hover:bg-blue-700"
                >
                    {btn}
                </button>
            ))}
        </div>
    );
}

function evaluateExpression(expression) {
    const operators = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
        "*": (a, b) => a * b,
        "/": (a, b) => a / b,
    };

    const precedence = {
        "+": 1,
        "-": 1,
        "*": 2,
        "/": 2,
    };

    const values = [];
    const ops = [];
    let num = "";

    const applyOperator = () => {
        const operator = ops.pop();
        const b = values.pop();
        const a = values.pop();
        const result = operators[operator](a, b);
        values.push(result);
    };

    
    for (let i = 0; i < expression.length; i++) {
        const char = expression[i];

        if (/\d/.test(char)) {
            num += char; 
        } else if (char in operators) {
            if (num) {
                values.push(Number(num));
                num = "";
            }

            while (ops.length && precedence[ops[ops.length - 1]] >= precedence[char]) {
                applyOperator();
            }
            ops.push(char); 
        }
    }

    if (num) {
        values.push(Number(num)); 
    }

    // Apply remaining operators
    while (ops.length) {
        applyOperator();
    }

    return values[0];
}

export default Calculator;

import React from "react";
import { motion, useScroll } from "framer-motion";
import { useRef } from "react";
import { useWindowSize } from "@react-hook/window-size";
import ResumeCard from "./ResumeCard";

export default function Education() {
  const ref = useRef(null);
  const [width] = useWindowSize(); 


  const offsetValue = width < 750 ? ["0 1", "0.4 1"] : ["0 1", "0.8 1"];

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: offsetValue,
  });

  return (
    <motion.div
      className="education-container"
      ref={ref}
      style={{
        scale: scrollYProgress,
        opacity: scrollYProgress,
      }}
    >
      <div className="education-section">
        <div className="education-header">
          <p className="education-date">2013 - 2022</p>
          <h2 className="education-title">Education</h2>
        </div>
        <div className="education-cards">
          <ResumeCard
            title="MSc in Information and Electronic Engineering"
            subTitle="International Hellenic University (2016 - 2022)"
            des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Secondary School (2010 - 2016)"
            des="Secondary school education, for ages 12 to 18, includes lower secondary (middle school) and upper secondary (high school). Students study a broad range of subjects initially, then specialize in upper secondary, preparing for higher education or careers. It often ends with key exams."
          />
        </div>
      </div>
      <div className="education-section">
        <div className="education-header">
          <p className="education-date">2023 - 2025</p>
          <h2 className="education-title">Job Experience</h2>
        </div>
        <div className="education-cards">
          <ResumeCard
            title="Research & Informatics Technology Office"
            subTitle="Greek Army, Research & Informatics Technology Office (May 2023- March 2024)"
            des="During my compulsory military service as a soldier of the Research and Technology Office, I worked as an IT Support Specialist and Systems Administrator."
          />
        </div>
        <div className="education-cards">
          <ResumeCard
            title="ERP DEVELOPER"
            subTitle="CFORCE, ERP Developer (Jan 2025 - March 2025)"
            des="ERP systems with experience in Softone and Plan B invoicing software.
                  • SQL database management and optimization for efficient data handling.
                  • Customer support, providing tailored solutions and technical guidance.
                  • Pascal programming.
                  • Computer hardware and software troubleshooting."
          />
        </div>
        <div className="education-cards">
          <ResumeCard
            title="Frontend Engineer"
            subTitle=" StellarBlue (March 2025 - Now)"
            des="Developing dynamic and responsive web applications using Vue.js and modern front-end technologies."
          />
        </div>
      </div>
    </motion.div>
  );
}

import { motion } from 'framer-motion';

export default function Skill ({skill,percent}) {
return (
    <div className="overflow-x-hidden">
     <p className="text-sm uppercase font-medium text-white">{skill}</p>
       <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
        <motion.span
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        style={{width:percent}}
        className=" h-full bg-gradient-to-r from-blue-600 via-cyan-600 to-cyan-400 rounded-md relative"
        >
        <span className="absolute -top-7 right-0 text-white">{percent}</span>
        </motion.span>
        </span>
    </div>
);
}
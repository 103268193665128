import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

export default function Timeline() {
  const ref = useRef(null);

  
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'center start'],
  });

  
  const x1Transform = useTransform(scrollYProgress, [0, 0.4], ['-100vw', '-5vw']);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.5], [0, 1]);

  const x2Transform = useTransform(scrollYProgress, [0, 0.6], ['100vw', '5vw']);
  const x3Transform = useTransform(scrollYProgress, [0.35, 0.7], ['-100vw', '-5vw']);
  

  return (
    <>
      <h2 id='projects' className=" pt-20 projects text-4xl flex align-center justify-center font-extrabold ">
        Projects
      </h2>
    <section
      ref={ref}
      className="py-16 relative flex justify-center items-center overflow-hidden"
    >
      <div className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
        <ul className="space-y-8">
          {/* Project 1 */}
          <li className="flex flex-col items-center">
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
                
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className='text-cyan-300'
                />
              </svg>
            </div>
            <div className="timeline-start mb-10 text-end w-[300px] max-sm:w-64 ">
              <time className="font-mono italic relative right-1/2 transform max-sm:text-sm overflow-hidden text-white ">Project 1 - Aug 2024</time>
              <motion.div
              className='isolate aspect-video rounded-xl bg-white/5 shadow-lg ring-1 ring-black/5 text-white'
              style={{
                x: x1Transform,
                opacity: opacityTransform,
              }}>
              <div className="text-lg font-black p-2 text-slate-400 "
              >Burger Order App</div>
              This project is a simple Burger Order app where users can select their desired burger and customize it. It includes options to choose toppings, sauces, and size. The app then displays the total cost and sends the order to the server.
              <button className=" text-end bg-emerald-400 text-white px-4 py-2 rounded-md hover:bg-cyan-400 transition-colors duration-300"
              onClick={() => window.open('https://burger-order-app-phi.vercel.app/', '_blank')}>Explore App</button>
              </motion.div>
            </div>
          </li>

          {/*  Project 2 */}
          <li className="flex flex-col items-center">
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
               
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="text-cyan-300"
                />
              </svg>
            </div>
            <div className="timeline-start mb-10 text-start w-72 max-sm:w-64 ">
              <time className="font-mono italic relative left-1/2 transform max-sm:text-sm overflow-hidden text-white">Project 2 - Sept 2024</time>
              <motion.div
              className='isolate aspect-video rounded-xl bg-white/5 shadow-lg ring-1 ring-black/5 text-white'
              style={{
                x: x2Transform,
                opacity: opacityTransform,
              }}>
              <div className="text-lg font-black p-2 text-slate-400 ">SymptomLog</div>
              SymptomLog is an app for tracking symptoms, where users can log their daily symptoms and categorize them by intensity, frequency, and type. The data is saved to a database and can be visualized through charts for better health management.
              <button className="block bg-emerald-400 text-white px-4 py-2 rounded-md hover:bg-cyan-400 transition-colors duration-300"
              onClick={() => window.open('https://symptomlog.vercel.app/', '_blank')}>Explore App</button>
              </motion.div>
            </div>
          </li>


          <li className="flex flex-col items-center">
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
                
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className='text-cyan-300'
                />
              </svg>
            </div>
            <div className="timeline-start mb-10 text-end w-[300px] max-sm:w-64 ">
              <time className="font-mono italic relative right-1/2 transform max-sm:text-sm overflow-hidden text-white ">Project 3 - Feb 2025</time>
              <motion.div
              className='isolate aspect-video rounded-xl bg-white/5 shadow-lg ring-1 ring-black/5 text-white'
              style={{
                x: x3Transform,
                opacity: opacityTransform,
              }}>
              <div className="text-lg font-black p-2 text-slate-400 "
              >Timeseries Data Visualization</div>
               A Vue.js project that displays timeseries data into a table and visualizes it in a line graph. The table is editable, automatically updating the chart, and includes a date filter for flexible data exploration.
              <button className=" text-end bg-emerald-400 text-white px-4 py-2 rounded-md hover:bg-cyan-400 transition-colors duration-300"
              onClick={() => window.open('https://timeseries-data-visualization-omega.vercel.app/', '_blank')}>Explore App</button>
              </motion.div>
            </div>
          </li>
        
        </ul>
      </div>
    </section>
    </>
  );
}

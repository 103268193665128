export default function Header() {
  return (
    <header className="fixed top-0 w-full bg-gradient-to-r from-black/60 via-gray-900/60 to-black/60 text-white py-5 text-right z-50 h-[70px]">
      <div className="flex justify-center items-center gap-8 max-sml:text-sm max-sml:gap-2">
        <a
          href="#about"
          className="px-3 py-1 text-md font-bold tracking-wider hover:text-teal-300 hover:border-teal-300 transition duration-300"
        >
          About
        </a>
        <a
          href="#resume"
          className="px-3 py-1 text-md font-bold tracking-wider hover:text-teal-300 hover:border-teal-300 transition duration-300"
        >
          Resume
        </a>
        <h2 className="text-center mb-4 font-extrabold tracking-tight underline underline-offset-2">
          Tsiakiris Nikos
        </h2>
        <a
          href="#projects"
          className="px-3 py-1 text-md font-bold tracking-wider hover:text-teal-300 hover:border-teal-300 transition duration-300"
        >
          Projects
        </a>
        <a
          href="#contact"
          className="px-3 py-1 text-md font-bold tracking-wider hover:text-teal-300 hover:border-teal-300 transition duration-300"
        >
          Contact
        </a>
      </div>
    </header>
  );
}

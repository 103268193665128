import { motion } from 'framer-motion';
import Skill from './Skill';


const skills1 = [
  { skill: "JavaScript", percent: "90%" },
  { skill: "HTML 5", percent: "95%" },
  { skill: "CSS", percent: "90%" },
  { skill: "TypeScript", percent: "75%" },
  { skill: "React", percent: "80%" }, 
];

const skills2 = [
  { skill: "Vue", percent: "75%" },
  { skill: "Nextjs", percent: "80%" },
  { skill: "Redux", percent: "70%" },
  { skill: "Tailwind", percent: "80%" },
  { skill: "Git-Github", percent: "85%" }, 
];

export default function Skills() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* Left Side */}
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Features
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Development Skills</h2>
        </div>

        <div className="flex flex-col gap-6">
          {/* Skill Item */}
        {skills1.map((item, index) => (
        <Skill key={index} skill={item.skill} percent={item.percent} />
        ))}
        </div>
      </div>
      {/* Right Side */}
      <div className="w-full lgl:w-1/2 lgl:mt-48">
        <div className="flex flex-col gap-6">
          {/* Skill Item */}
        {skills2.map((item, index) => (
          <Skill key={index} skill={item.skill} percent={item.percent} />
        ))}
        </div>
      </div>
    </motion.div>
  );
}

import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import bgvid from "../image/background_video.mp4"; 

export default function Welcome() {
    const [animateText, setAnimateText] = useState(false);
    const videoRef = useRef(null); 
    const buttonRef = useRef(null); 

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimateText(true); 
            if (videoRef.current) {
                videoRef.current.play(); 
            }
            if (buttonRef.current) {
                buttonRef.current.style.opacity = 1;
            }
        }, 4700); 

        return () => clearTimeout(timer); 
    }, []);

    const itemVariants = {
        hidden: { opacity: 0, y: 0, height: 0 },
        visible: { opacity: 1, y: 10, height: 180 },
    };

    
   
    return (
        <div className="relative h-screen overflow-hidden grid place-items-center align-items-center gap-7"
         style={{ backgroundColor: "black" }}>
            <video
                ref={videoRef}
                muted
                className="absolute top-0 left-0 w-full h-full object-cover"
            >
                <source src={bgvid} type="video/mp4" />
                
            </video>

            <motion.div
                className="trails-main top-[55%] z-10 xl:text-[200px] md:text-[140px] sm:text-[90px] text-[75px]"
                style={{
                    textShadow:
                        "1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 4px 0 grey, 1px 5px 0 grey, 1px 6px 0 grey, 1px 7px 0 grey, 1px 8px 0 grey, 5px 13px 15px black",
                        opacity: 0.6,
                    }}
            >
                {["Welcome"].map((text, index) => (
                    <motion.h2
                        className="trails-text"
                        key={index}
                        variants={itemVariants}
                        initial="hidden"
                        animate={animateText ? "visible" : "hidden"} 
                        style={{ display: "inline-block", color: 'azure' }}
                        transition={{
                            delay: index * 0.5, 
                            duration: 1.2, 
                            type: "tween", 
                            ease: "easeInOut", 
                        }}
                    >
                        {text}
                    </motion.h2>
                ))}
            </motion.div>

            <motion.p
                className="titlehead  relative text-center z-10 w-full text-xl sm:text-[20px] text-[20px]"
                style={{
                    fontFamily: 'Roboto Slab, Poppins, sans-serif',
                    textShadow:
                        "1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 3px 0 grey, 5px 5px 6px black",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: animateText ? 0.6 : 0 }}
                transition={{ delay: 1, duration: 1.2 }}
            >
                Tsiakiris Nikos
            </motion.p>
            
            <motion.div
                className="relative flex items-center justify-center w-32 h-32"
                initial={{ opacity: 0 }}
                animate={{ opacity: animateText ? 0.4 : 0 }}
                transition={{ delay: 1.5, duration: 1.2 }}
            >
    
                {/* Rotating Dashed Circle */}
                <div className="absolute w-24 h-24 border-2 border-dashed border-white rounded-full animate-spin-slow" style={{ zIndex: 20 }}></div>

                {/* Arrow Button */}
                <motion.a
                    href="#about"
                    className="text-2xl text-white relative border-2 border-white p-3 rounded-full hover:bg-gray-700 hover:text-black bg-transparent transition duration-300"
                    style={{ zIndex: 30 }}
                >
                    <span className="text-3xl">&#8595;</span>
                </motion.a>
            </motion.div>


        </div>
    );
}
